<template>
  <div>    
    <b-row>
      <b-col :class="{'col-md-7':!hideConfigurations,'col-md-12':hideConfigurations}">
        <b-card header-text-variant="white"
                header-bg-variant="dark"
                header-border-variant="dark"
                footer-text-variant="dark"
                footer-bg-variant="dark"
                footer-border-variant="secondary"> 
          
          <div slot="header">
            <strong> Control </strong>  
            <b-button v-if="!statusEdit" @click="reorder()" class="pull-right" size="sm" title="Ordenar" variant="outline-primary"> 
              <b-icon icon="list" />
            </b-button>                   
            <b-button v-if="!statusEdit" @click="edit()" class="pull-right mr-2" size="sm" title="Editar" variant="outline-warning"> 
              <b-icon icon="pencil-square" />
            </b-button>               
            <b-button v-if="statusEdit" @click="add()" class="pull-right mr-2" size="sm" title="Agregar" variant="outline-success">
              <b-icon icon="plus-circle" />
            </b-button>            
          </div>
                    
          <div v-if="block.control.length">            
            <div v-if="statusEdit">
              <b-card no-body class="mb-1" v-for="(item, index) in block.control" :key="index">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle="'accordion-' + index" variant="secondary" class="text-left">
                    <b>Item {{index + 1}}</b>
                  </b-button>              
                </b-card-header>
                <b-collapse :id="'accordion-'+ index" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>

                    <Testimonies v-if="reference=='testimonies' && type!='custom'" 
                                 :dataBlock="item" 
                                 :dataIndex="index"
                                 :controlTemporal="blockTemporal[index]" 
                                 mode="edit"                                  
                                 @get-object="loadData($event, index)"
                                 @get-object-control-temporal="loadDataTemporal($event, index)"/>

                    <Slider v-if="reference=='slider' && type!='custom'"  
                                :dataBlock="item" 
                                :dataIndex="index"
                                :controlTemporal="blockTemporal[index]" 
                                :typeSlider="block.config.typeSlider"
                                mode="edit"                                 
                                @get-object="loadData($event, index)"
                                @get-object-control-temporal="loadDataTemporal($event, index)"/>

                    <BoxIcons v-if="reference=='box_icons' && type!='custom'"  
                                :dataBlock="item" 
                                :dataIndex="index"
                                :controlTemporal="blockTemporal[index]" 
                                mode="edit"                                 
                                @get-object="loadData($event, index)"
                                @get-object-control-temporal="loadDataTemporal($event, index)"/>

                    <BoxImages v-if="reference=='box_images' && type!='custom'"  
                              :dataBlock="item" 
                              :dataIndex="index"
                              :controlTemporal="blockTemporal[index]" 
                              mode="edit"                               
                              @get-object="loadData($event, index)"
                              @get-object-control-temporal="loadDataTemporal($event, index)"/>

                    <CarouselImages v-if="reference=='carousel_images' && type!='custom'"  
                                    :dataBlock="item" 
                                    :dataIndex="index"
                                    :controlTemporal="blockTemporal[index]" 
                                    mode="edit"                                     
                                    @get-object="loadData($event, index)"
                                    @get-object-control-temporal="loadDataTemporal($event, index)"/>

                    <BlogSimple v-if="reference=='blog_simple' && type!='custom'"  
                                :dataBlock="item" 
                                :dataConfig="block.config"
                                :dataIndex="index"
                                :controlTemporal="blockTemporal[index]" 
                                mode="edit"                                 
                                @get-object="loadData($event, index)"
                                @get-object-control-temporal="loadDataTemporal($event, index)"/>

                    <ServiceSimple v-if="reference=='service_simple' && type!='custom'"  
                                  :dataBlock="item" 
                                  :dataIndex="index"
                                  :controlTemporal="blockTemporal[index]" 
                                  mode="edit"                                 
                                  @get-object="loadData($event, index)"
                                  @get-object-control-temporal="loadDataTemporal($event, index)"/> 

                    <Maps v-if="reference=='maps' && type!='custom'"  
                          :dataBlock="item" 
                          :dataIndex="index"
                          :controlTemporal="blockTemporal[index]" 
                          mode="edit"                                 
                          @get-object="loadData($event, index)"
                          @get-object-control-temporal="loadDataTemporal($event, index)"/> 

                    <ImagesGalleryCategorized v-if="reference=='images_gallery_categorized' && type!='custom'"  
                                              :dataBlock="item" 
                                              :dataIndex="index"
                                              :controlTemporal="blockTemporal[index]" 
                                              mode="edit"                                 
                                              @get-object="loadData($event, index)"
                                              @get-object-control-temporal="loadDataTemporal($event, index)"/> 

                    <CategoriesProductFilter v-if="reference=='categories_ecommerce_filter' && type!='custom'"  
                                            :dataBlock="item" 
                                            :dataIndex="index"
                                            :controlTemporal="blockTemporal[index]" 
                                            mode="edit"                               
                                            @get-object="loadData($event, index)"
                                            @get-object-control-temporal="loadDataTemporal($event, index)"/>

                    <LinesProductFilter v-if="reference=='lines_ecommerce_filter' && type!='custom'"  
                                        :dataBlock="item" 
                                        :dataIndex="index"
                                        :controlTemporal="blockTemporal[index]" 
                                        mode="edit"                               
                                        @get-object="loadData($event, index)"
                                        @get-object-control-temporal="loadDataTemporal($event, index)"/>                              

                    <AccessDirect v-if="reference=='access_direct' && type!='custom'"  
                                  :dataBlock="item" 
                                  :dataIndex="index"
                                  :controlTemporal="blockTemporal[index]" 
                                  mode="edit"                               
                                  @get-object="loadData($event, index)"
                                  @get-object-control-temporal="loadDataTemporal($event, index)"/>

                    <Custom v-if="type=='custom'"  
                                  :dataBlock="item" 
                                  :dataIndex="index"
                                  :controlTemporal="blockTemporal[index]" 
                                  :contentBlock="dataBlock"
                                  mode="edit"                               
                                  @get-object="loadData($event, index)"
                                  @get-object-images="loadDataImages($event, index)"
                                  @get-object-control-temporal="loadDataTemporal($event, index)"/>                                  

                    <b-row>
                      <b-col md="12">
                        <b-button variant="outline-danger" @click="remove(index)" size="sm" class="pull-right">
                          Borrar
                          <i class="fa fa-trash"></i>
                        </b-button>
                      </b-col>
                    </b-row>                

                  </b-card-body>
                </b-collapse>
              </b-card>            
            </div>
            <div v-else>
              <div v-for="(item, index) in block.control" :key="index">

                <Testimonies v-if="reference=='testimonies' && type!='custom'"  
                             :dataBlock="item" 
                             :dataIndex="index"
                             :controlTemporal="blockTemporal[index]" 
                             mode="list" 
                             @get-object="loadData($event, index)"
                             @get-object-control-temporal="loadDataTemporal($event, index)"/>

                <Slider v-if="reference=='slider' && type!='custom'"
                        :dataBlock="item" 
                        :dataIndex="index"
                        :controlTemporal="blockTemporal[index]" 
                        :typeSlider="block.config.typeSlider"
                        mode="list" 
                        @get-object="loadData($event, index)"
                        @get-object-control-temporal="loadDataTemporal($event, index)"/>

                <BoxIcons v-if="reference=='box_icons' && type!='custom'"  
                            :dataBlock="item" 
                            :dataIndex="index"
                            :controlTemporal="blockTemporal[index]" 
                            mode="list" 
                            @get-object="loadData($event, index)"
                            @get-object-control-temporal="loadDataTemporal($event, index)"/>

                <BoxImages v-if="reference=='box_images' && type!='custom'"  
                           :dataBlock="item" 
                           :dataIndex="index"
                           :controlTemporal="blockTemporal[index]" 
                           mode="list" 
                           @get-object="loadData($event, index)"
                           @get-object-control-temporal="loadDataTemporal($event, index)"/>

                <CarouselImages v-if="reference=='carousel_images' && type!='custom'"
                                :dataBlock="item" 
                                :dataIndex="index"
                                :controlTemporal="blockTemporal[index]" 
                                mode="list" 
                                @get-object="loadData($event, index)"
                                @get-object-control-temporal="loadDataTemporal($event, index)"/>

                <BlogSimple v-if="reference=='blog_simple' && type!='custom'"  
                            :dataBlock="item" 
                            :dataConfig="block.config"
                            :dataIndex="index"
                            :controlTemporal="blockTemporal[index]" 
                            mode="list"                                 
                            @get-object="loadData($event, index)"
                            @get-object-control-temporal="loadDataTemporal($event, index)"/>

                <ServiceSimple v-if="reference=='service_simple' && type!='custom'"  
                              :dataBlock="item" 
                              :dataIndex="index"
                              :controlTemporal="blockTemporal[index]" 
                              mode="list"                                 
                              @get-object="loadData($event, index)"
                              @get-object-control-temporal="loadDataTemporal($event, index)"/>   

                <Maps v-if="reference=='maps' && type!='custom'"  
                      :dataBlock="item" 
                      :dataIndex="index"
                      :controlTemporal="blockTemporal[index]" 
                      mode="list"                                 
                      @get-object="loadData($event, index)"
                      @get-object-control-temporal="loadDataTemporal($event, index)"/>

                <ImagesGalleryCategorized v-if="reference=='images_gallery_categorized' && type!='custom'"  
                                          :dataBlock="item" 
                                          :dataIndex="index"
                                          :controlTemporal="blockTemporal[index]" 
                                          mode="list"                                 
                                          @get-object="loadData($event, index)"
                                          @get-object-control-temporal="loadDataTemporal($event, index)"/> 

                <CategoriesProductFilter v-if="reference=='categories_ecommerce_filter' && type!='custom'"  
                                        :dataBlock="item" 
                                        :dataIndex="index"
                                        :controlTemporal="blockTemporal[index]" 
                                        mode="list" 
                                        @get-object="loadData($event, index)"
                                        @get-object-control-temporal="loadDataTemporal($event, index)"/>  

                <LinesProductFilter v-if="reference=='lines_ecommerce_filter' && type!='custom'"  
                                    :dataBlock="item" 
                                    :dataIndex="index"
                                    :controlTemporal="blockTemporal[index]" 
                                    mode="list" 
                                    @get-object="loadData($event, index)"
                                    @get-object-control-temporal="loadDataTemporal($event, index)"/>   

                <AccessDirect v-if="reference=='access_direct' && type!='custom'"  
                              :dataBlock="item" 
                              :dataIndex="index"
                              :controlTemporal="blockTemporal[index]" 
                              mode="list" 
                              @get-object="loadData($event, index)"
                              @get-object-control-temporal="loadDataTemporal($event, index)"/>

                <Custom v-if="type=='custom'"  
                              :dataBlock="item" 
                              :dataIndex="index"
                              :controlTemporal="blockTemporal[index]" 
                              :contentBlock="dataBlock"
                              mode="list" 
                              @get-object="loadData($event, index)"
                              @get-object-images="loadDataImages($event, index)"
                              @get-object-control-temporal="loadDataTemporal($event, index)"/>

              </div>
            </div>
          </div>
          <div v-else>
            <b-alert show variant="warning">No se encontraron registros</b-alert>
          </div>

        </b-card>
      </b-col>
      <b-col md="5" v-if="!hideConfigurations">
        <b-card header-text-variant="white"
                header-bg-variant="dark"
                header-border-variant="dark"
                footer-text-variant="dark"
                footer-bg-variant="dark"
                footer-border-variant="secondary"> 

          <div slot="header">
            <strong> Configuración </strong>
          </div>

          <b-row>
            <b-col md="12" v-if="showTitle">      
              <b-form-group label="Título">
                <b-form-input type="text"
                              size="sm"
                              v-model="block.config.title"                          
                              placeholder="Ingresar un título">
                </b-form-input>
              </b-form-group>
            </b-col>  

            <b-col md="12" v-if="showSubTitle">        
              <b-form-group label="Subtítulo">
                <b-form-input type="text"
                              size="sm"
                              v-model="block.config.subtitle"                          
                              placeholder="Ingresar un subtítulo">
                </b-form-input>
              </b-form-group>
            </b-col>      

            <b-col md="12" v-if="showCategoryBlog">        
              <b-form-group label="Categorías Blog" :disabled="statusEdit">
                <InputList :list="block.config.categoryBlog" 
                           @load-list="block.config.categoryBlog = $event"/>                                
              </b-form-group>
            </b-col>      

            <b-col md="12" v-if="showFontColor">       
              <b-form-group label="Color de Texto">
                <b-form-input v-model="block.config.fontColor" type="color"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="12" v-if="showBackgroundColor">      
              <b-form-group label="Color de Fondo ">
                <b-form-input v-model="block.config.backgroundColor" type="color"></b-form-input>
              </b-form-group>
            </b-col> 

            <b-col md="12" v-if="showBackgroundImage">
              <b-form-group label="Imagen de Fondo" description="Imagen 1: se utiliza para desktop | Imagen 2: se uliza para mobile (si no esta cargada se usa la imagen 1)">                          
                <Imagen :images="block.config.backgroundImage" 
                        @load-image="block.config.backgroundImage = $event" 
                        typeImage="BackgroundImage"
                        :multipleImage="true"
                        :maxImage="2" 
                        :compressImage="true" />
              </b-form-group> 
            </b-col>          

            <b-col md="12" v-if="showMessageForm">
              <b-form-group label="Consultas" description="Formulario donde se guardarán las consultas sobre un servicio">
                <b-form-select v-model="block.config.messageFormsID" :options="arr.select.forms"></b-form-select>
              </b-form-group>          
            </b-col>    

            <b-col md="12" v-if="showMapZoom">       
              <b-form-group label="Mapa Zoom">
                <b-form-input v-model="block.config.mapsZoom" type="number"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="12" v-if="showTypeSlider">       
              <b-form-group label="Tipo de Slider">                
                <b-form-select v-model="block.config.typeSlider" :options="arr.select.typeSlider"></b-form-select>                    
              </b-form-group>
            </b-col>            

            <b-col md="12" v-if="showHeightSlider">       
              <b-form-group label="Slider Alto" description="Medida expresada en pixeles (px)">
                <b-form-input v-model="block.config.heightSlider" type="number"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="12" v-if="showHoverPauseSlider">       
              <b-form-group label="Slider Transición">
                <b-form-checkbox v-model="block.config.havePauseHoverSlider" 
                                  switch 
                                  size="sm" 
                                  class="mt-2 mb-3">
                  ¿No pausar transición cuando el mouse se posiciona sobre la imagen?
                </b-form-checkbox>                                 
              </b-form-group>
            </b-col>

            <b-col md="12" v-if="showPaddingTop">      
              <b-form-checkbox v-model="block.config.havePaddingTop" 
                                switch 
                                size="sm" 
                                class="mt-4 pull-right">
                ¿Tiene Padding Superior?
              </b-form-checkbox> 
            </b-col> 

            <b-col md="12" v-if="showPaddingBottom">      
              <b-form-checkbox v-model="block.config.havePaddingBottom" 
                                switch 
                                size="sm" 
                                class="mt-4 pull-right">
                ¿Tiene Padding Inferior?
              </b-form-checkbox> 
            </b-col>       

            <b-col md="12" v-if="showFullWidht">
              <b-form-checkbox v-model="block.config.haveFullWidht" 
                                switch 
                                size="sm" 
                                class="mt-4 pull-right">
                ¿Tiene Ancho Completo?
              </b-form-checkbox>            
            </b-col>

            <b-col md="12" v-if="showStyleCSS">
              <b-form-group label="Estilos" description="Las clases CSS ingresadas se aplicarán solo a este bloque">
                <prism-editor v-model="block.config.stylesCSS" language="css" class="content-crud-block-css"></prism-editor>            
              </b-form-group>
            </b-col> 

            <b-col md="12">
              <b-alert show variant="info" v-if="hideConfigurations">
                Este bloque no posee configuración
              </b-alert>
            </b-col>

          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-card header-tag="header" footer-tag="footer" slot="footerBlocks">
      <b-row>
        <b-col>
          <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
            <i class="fa fa-angle-double-left"></i>
            Volver
          </b-button>   

          <b-button type="button" variant="dark" class="pull-right" size="sm" @click="save()">
            Guardar
            <i class="fa fa-save"></i>
          </b-button>                      
        </b-col>        
      </b-row>
    </b-card>
  </div>
</template>
<script>
  import Error from '@/handler/error'  
  import serviceAPI from './../services'

  import Testimonies from './types/multiple/website/_testimonies'
  import Slider from './types/multiple/website/_slider'
  import BoxIcons from './types/multiple/website/_boxIcons'
  import BoxImages from './types/multiple/website/_boxImages'
  import CarouselImages from './types/multiple/website/_carouselImages'
  import BlogSimple from './types/multiple/website/_blogSimple'
  import ServiceSimple from './types/multiple/website/_serviceSimple'
  import Maps from './types/multiple/website/_maps'
  import ImagesGalleryCategorized from './types/multiple/website/_imagesGalleryCategorized'
  import CategoriesProductFilter from './types/multiple/ecommerce/_categoriesEcommerceFilter'
  import LinesProductFilter from './types/multiple/ecommerce/_linesEcommerceFilter'
  import AccessDirect from './types/multiple/website/_accessDirect'
  import Custom from './types/multiple/_custom'
  
  import Imagen from '@/components/inc/image/image'
  import PrismEditor from 'vue-prism-editor'
  import "prismjs";
  import "prismjs/themes/prism.css";
  import InputList from '@/components/inc/inputList/inputList'

  export default {
    components: {    
      PrismEditor,
      Imagen,   
      InputList,
      Testimonies,
      Slider,
      BoxIcons,  
      BoxImages,      
      CarouselImages,
      BlogSimple,
      ServiceSimple,
      Maps,
      ImagesGalleryCategorized,
      CategoriesProductFilter,
      LinesProductFilter,      
      AccessDirect,
      Custom,
    },      
    props: {
      dataBlock:{
        type: Object,
        default: {}
      },
      reference: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: ''
      }             
    },    
    data: () => {
      return {   
        blockTemporal: [],
        block: {
          control: [],
          config: {
            title: '',
            subtitle: '',
            categoryBlog: null,
            fontColor: '#000000',
            backgroundColor: '#ffffff', 
            backgroundImage: [],
            mapsZoom: 13,
            typeSlider: 'default',
            heightSlider: 500,
            havePauseHoverSlider: false,
            havePaddingBottom: true,
            havePaddingTop: true,
            haveFullWidht: false,
            stylesCSS: '',
            messageFormsID: 0,
          },
          images: ['image'],
          imagesConfig: ['backgroundImage'],
          multiple: true,
        },  
        arr: {
          select: {
            typeSlider: [
              { value: 'default', text: 'Por defecto' },
              { value: 'effects_particle', text: 'Efecto con partículas' },
              { value: 'paint_brush', text: 'Efecto pincel' },
              { value: 'video', text: 'Video de Fondo' },
            ],
            forms: []
          }
        },
        statusEdit: false,                 
      }    
    },  
    created () {     
      if(this.dataBlock.content) { 
        var dataBlock = JSON.parse(this.dataBlock.content)   

        if(dataBlock) {
          if(dataBlock.control) {
            dataBlock.control.forEach((element, index) => {
              this.add(element)                    
            });       
          }
        }

        var imgConfg = []  
        var i = 0         
        var backgroundImage = 2
        if(backgroundImage) {          
          for (i; i < backgroundImage; i++) {                        
            if(dataBlock.config["backgroundImage_" + i]) {
              imgConfg.push({ path: dataBlock.config["backgroundImage_" + i] })
            }   
          }          
        }

        this.block.config.title = dataBlock.config.title
        this.block.config.subtitle = dataBlock.config.subtitle
        this.block.config.categoryBlog = dataBlock.config.categoryBlog
        this.block.config.fontColor = dataBlock.config.fontColor
        this.block.config.backgroundColor = dataBlock.config.backgroundColor
        this.block.config.backgroundImage = imgConfg
        this.block.config.mapsZoom = dataBlock.config.mapsZoom
        this.block.config.typeSlider = dataBlock.config.typeSlider
        this.block.config.heightSlider = dataBlock.config.heightSlider
        this.block.config.havePauseHoverSlider = dataBlock.config.havePauseHoverSlider
        this.block.config.havePaddingBottom = dataBlock.config.havePaddingBottom
        this.block.config.havePaddingTop = dataBlock.config.havePaddingTop
        this.block.config.haveFullWidht = dataBlock.config.haveFullWidht
        this.block.config.stylesCSS = dataBlock.config.stylesCSS
        this.block.config.messageFormsID = dataBlock.config.messageFormsID
      }     
    },
    mounted () {
      this.loadForms()
      
      // guardo una variable en el local storage (es una variable temporal)
      if(localStorage.getItem(window.location.origin + '_temporal_reorder')) {
        localStorage.removeItem(window.location.origin + '_temporal_reorder')
        this.$emit('open-reorder', this.block)         
      }
    },
    computed: {
      showTitle() {
        var reference = this.reference
        var status = false

        if(reference=='testimonies' ||
           reference=='box_icons' || 
           reference=='carousel_images' ||
           reference=='blog_simple' ||
           reference=='service_simple' ||
           reference=='images_gallery_categorized' ||
           reference=='maps' ||
           reference=='categories_ecommerce_filter' ||
           reference=='lines_ecommerce_filter' ||
           reference=='access_direct') 
        {
          status = true
        }

        return status
      },
      showSubTitle() {
        var reference = this.reference
        var status = false

        if(reference=='testimonies' ||
           reference=='box_icons' ||
           reference=='blog_simple' ||
           reference=='service_simple' ||
           reference=='images_gallery_categorized' ||
           reference=='maps' ||
           reference=='categories_ecommerce_filter' ||
           reference=='lines_ecommerce_filter' ||
           reference=='access_direct')
        {
          status = true
        }

        return status
      },
      showCategoryBlog() {
        var reference = this.reference
        var status = false

        if(reference=='blog_simple')
        {
          status = true
        }

        return status
      },
      showFontColor() {
        var reference = this.reference
        var status = false
        
        if(reference=='testimonies' ||
           reference=='box_icons' ||
           reference=='carousel_images' ||
           reference=='blog_simple' ||
           reference=='service_simple' ||
           reference=='images_gallery_categorized' ||
           reference=='maps' ||
           reference=='categories_ecommerce_filter' ||
           reference=='lines_ecommerce_filter' ||
           reference=='access_direct')
        {
          status = true
        }

        return status
      },
      showBackgroundColor() {
        var reference = this.reference
        var status = false

        if(reference=='testimonies' ||
           reference=='box_icons' ||
           reference=='box_images' ||
           reference=='carousel_images' ||
           reference=='blog_simple' ||
           reference=='service_simple' ||
           reference=='images_gallery_categorized' ||
           reference=='maps' ||
           reference=='categories_ecommerce_filter' ||
           reference=='lines_ecommerce_filter' ||
           reference=='access_direct') 
        {
          status = true
        }

        return status
      },
      showBackgroundImage() {
        var reference = this.reference
        var status = false

        if(reference=='testimonies' ||
           reference=='box_icons' ||
           reference=='box_images' ||
           reference=='carousel_images' ||
           reference=='blog_simple' ||
           reference=='service_simple' ||
           reference=='images_gallery_categorized' ||
           reference=='maps' ||
           reference=='categories_ecommerce_filter' ||
           reference=='lines_ecommerce_filter' ||
           reference=='access_direct') 
        {
          status = true
        }

        return status
      },
      showMapZoom() {
        var reference = this.reference
        var status = false

        if(reference=='maps') 
        {
          status = true
        }
        
        return status
      },
      showTypeSlider() {
        var reference = this.reference
        var status = false

        if(reference=='slider') 
        {
          status = true
        }
        
        return status
      },      
      showHeightSlider() {
        var reference = this.reference
        var status = false

        if(reference=='slider') 
        {
          if(this.block.config.typeSlider=='default') {
            status = true
          }          
        }
        
        return status
      },
      showHoverPauseSlider() {
        var reference = this.reference
        var status = false

        if(reference=='slider') 
        {
          if(this.block.config.typeSlider=='default') {
            status = true
          }          
        }
        
        return status
      },      
      showPaddingTop() {
        var reference = this.reference
        var status = false

        if(reference=='testimonies' ||
           reference=='box_icons' ||
           reference=='box_images' ||
           reference=='carousel_images' ||
           reference=='blog_simple' ||
           reference=='service_simple' ||
           reference=='images_gallery_categorized' ||
           reference=='maps' ||
           reference=='categories_ecommerce_filter' ||
           reference=='lines_ecommerce_filter' ||
           reference=='access_direct')
        {
          status = true
        }

        return status
      },
      showPaddingBottom() {
        var reference = this.reference
        var status = false

        if(reference=='testimonies' ||
           reference=='box_icons' ||
           reference=='box_images' ||
           reference=='carousel_images' ||
           reference=='blog_simple' ||
           reference=='service_simple' ||
           reference=='images_gallery_categorized' ||
           reference=='maps' ||
           reference=='categories_ecommerce_filter' ||
           reference=='lines_ecommerce_filter' ||
           reference=='access_direct')
        {
          status = true
        }

        return status
      },
      showFullWidht() {
        var reference = this.reference
        var status = false

        if(reference=='service_simple')
        {
          status = true
        }

        return status
      },
      showStyleCSS() {
        var reference = this.reference
        var status = false

        if(reference=='testimonies' ||
           reference=='box_icons' || 
           reference=='box_images' || 
           reference=='slider' || 
           reference=='carousel_images' ||
           reference=='blog_simple' ||
           reference=='service_simple' ||
           reference=='images_gallery_categorized' ||
           reference=='maps' ||
           reference=='categories_ecommerce_filter' ||
           reference=='lines_ecommerce_filter' ||
           reference=='access_direct') 
        {
          status = true
        }

        return status
      },
      showMessageForm() {
        var reference = this.reference
        var status = false

        if(reference=='service_simple') 
        {
          status = true
        }
        
        return status
      },      
      hideConfigurations() {
        var status = true
        if(this.showTitle ||
           this.showSubTitle ||
           this.showFontColor ||
           this.showBackgroundColor ||
           this.showBackgroundImage ||
           this.showMapZoom ||
           this.showPaddingTop ||
           this.showPaddingBottom ||
           this.showStyleCSS) 
        {
          status = false
        }

        return status
      },
    },
    methods:{          
      add(item) {              
        if(item) {
          this.block.control.push(item)
        } else {
          this.block.control.push(null)
        }        
      },
      edit() {
        this.statusEdit = true        
        if(this.block.control.length==0) {
          this.add()          
        }
      },      
      remove(index) {
        this.$bvModal.msgBoxConfirm('¿Desea borrar el item?', {
          title: 'Borrar Item',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {   
            let loader = this.$loading.show();      

            this.block.control.splice(index,1)
            var tmp = this.block.control
            this.block.control = [];        
                        
            setTimeout(()=>{              
              tmp.forEach((element1, index1) => {                
                this.add(element1)                    
              });              
              loader.hide()   
            },1000)          
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })          
      },

      loadData(item, index) {
        this.block.control[index] = item                
      },
      loadDataImages(item, index) {
        this.block.images = item        
      },
      loadDataTemporal(item, index) {
        this.blockTemporal[index] = item                   
      },      
      save() {             
        this.$emit('save-object', this.block) 
      }, 

      reorder(items) {           
        localStorage.setItem(window.location.origin + '_temporal_reorder', true)
        window.location.reload()                
      },  
      
      loadForms() {
        let loader = this.$loading.show();    
        var result = serviceAPI.obtenerForms()

        result.then((response) => {         
          var data = response.data
          
          data.forEach(element => {
            this.arr.select.forms.push({
              value: element.id,
              text: element.name
            })
          });
          
          loader.hide()   
        })
        .catch(error => {                   
          loader.hide()                 
          this.$awn.alert(Error.showError(error))
        });            
      }         
    }
  }
</script>
<style>
  .content-crud-block-css {
    height: 150px;
  }
  .content-crud-block-css pre {
    height: 150px;
  }  
</style>